import React from 'react'
import Layout from '../../components/layout'
import LightBox from '../../components/lightbox/lightbox'

import p1 from '../../images/pages/xpert/XpertHRM-0-Uebersicht-2.jpg'
import p2 from '../../images/pages/xpert/XpertHRM-1-Mitarbeiter-2.jpg'
import p3 from '../../images/pages/xpert/XpertHRM-2-Organisation.jpg'
import p4 from '../../images/pages/xpert/XpertHRM-3-Mitarbeiter-Dokumente.jpg'
import p5 from '../../images/pages/xpert/XpertHRM-4-Mitarbeiterskills.jpg'
import p6 from '../../images/pages/xpert/XpertPAY-1-Mitarbeiter-Lohninput.jpg'
import p7 from '../../images/pages/xpert/XpertPAY-2-Mitarbeiter-Abrechnung.jpg'
import p8 from '../../images/pages/xpert/XpertPAY-3-Mitarbeiter-Kinder.jpg'
import p9 from '../../images/pages/xpert/XpertPAY-4-Lohnlauf.jpg'
import p10 from '../../images/pages/xpert/org-manager-Fehlzeiten-pro-OE.jpg'
import p11 from '../../images/pages/xpert/org-manager-Budget.jpg'
import p12 from '../../images/pages/xpert/HR-Reporting-Nationalitaet-Teilzeit.jpg'
import p13 from '../../images/pages/xpert/HR-Reporting-Alter-Dienstalter.jpg'
import p14 from '../../images/pages/xpert/BI4HR-HRCockpit.jpg'
import p15 from '../../images/pages/xpert/BI4HR-FTEproOE.jpg'
import p16 from '../../images/pages/xpert/BI4HR-FluktuationproOE.jpg'
import p17 from '../../images/pages/xpert/BI4HR-Lohnaenderungen.jpg'
import p18 from '../../images/pages/xpert/BI4HR-Salaeranalyse-pro-Funktion.jpg'
import p19 from '../../images/pages/xpert/BI4HR-Jahreslohn-pro-Funktion.jpg'
import p20 from '../../images/pages/xpert/SpeedREP-Main.jpg'
import p21 from '../../images/pages/xpert/SpeedREP-Auswertung.jpg'
import p22 from '../../images/pages/xpert/SpeedREP-Excel.jpg'

const xpert = () => (
  <Layout>
    <section id="personalinformationssystem" className="section-wrapper">
      <header className="section-header">
        <h3>Xpert.HRM</h3>
      </header>
      <div className="post" id="overview">
        <h3>Übersicht</h3>
        <p>
          Noch während des ganzen Lebenszyklus von Xpert.HRM bieten wir weiterhin Kunden IT/HR-Dienstleistungen für dieses Personalinformationssystems an. Sollte eine Xpert.HRM Ablösung durch Abacus HR geplant sein, haben wir für Abacus die nötigen Migrationsprogramme entwickelt und sind der ideale Projektpartner, um das alte System erfolgreich in die neue zukunftweisende Abacuslösung zu überführen.
          Als langjährige Xpert.HRM Implementierer bieten wir Ihnen folgende Infos und Unterstützung in allen Belangen an:
        </p>
        <p>Die Software verfügt über ein modulares Konzept und bietet umfassende Funktionalität.</p>
        <br /> <br />
        Es folgt eine Übersicht zu den Modulen
                <LightBox type="images" images={overviewImages} thumbnailHeight={90} />
      </div>
      <div className="post" id="lohnPersonal">
        <h3>Lohnbuchhaltung und Personalstammdaten</h3>
        Das Modul Lohnbuchhaltung (Payroll) ist in hohem Masse parametrierbar. Es zeichnet sich aus durch:
                <br />
        <ul>
          <li>Hohen Automatisierungsgrad der Lohnbuchhaltung</li>
          <li>Swissdec zertifizierte Lohnbuchhaltung mit neuem Lohnausweis</li>
          <li>Integriertes elektronisches Lohnmeldeverfahren</li>
          <li>Führung von Mehrfachverträgen</li>
          <li>Einfache Verwaltung sämtlicher Lohnbestandteile (Zulagen, Sozialkosten, automatische Quellensteuer und Kinderzulagen, Fringe Benefits, Incentivepläne u.s.w.)</li>
          <li>Branchenunabhängigkeit</li>
          <li>Beliebig viele Abrechnungen pro Periode</li>
          <li>Beliebige Gruppierung der Mitarbeitenden in Abrechnungsgruppen</li>
          <li>Unterteilung von Personen in Kategorien zwecks hoher Automatisierung</li>
          <li>Frei definierbarer Lohnartenstamm</li>
          <li>Frei definierbare Rechenformeln für Spezialfälle</li>
          <li>
            Zahlreiche Standardauswertungen wie: AHV- und UVG-Liste (Suva), Quellensteuerabrechnung, Lohnabrechnungen und Lohnausweise, Geburtstagsliste,
            Kinderzulage-, FAK-Abrechnung etc.
                        </li>
          <li>Die Suva geprüfte Standardparametrisierung wird mitgeliefert</li>
        </ul>
        <LightBox type="images" images={p1Images} thumbnailHeight={90} />
      </div>
      <div className="post" id="stellenOrg">
        <h3>Stellenplan und Organisation</h3>
        Der Stellenplan gibt Überblick über die Organisation, die Belegung der Stellen, freie Stellen und deren Bewertung. Die Organisation kann auf der Zeitachse in
        verschiedenen parallelen Varianten geführt werden.
                <br /><br />
        Organisationsaufbau:
                <br />
        <ul>
          <li>Mehrere parallele Organisationen sind möglich</li>
          <li>Grafische Darstellung in Browserstruktur inkl. Chart Wizard</li>
          <li>Drag and Drop für die Organisationserstellung</li>
          <li>Zeitbezogene Unterordnung (History)</li>
          <li>Zahlreiche Informationen, Soll-Werte pro Organisationseinheit</li>
          <li>Zeitbezogene Belegung der Stelle durch Mitarbeitende</li>
          <li>Stellenbelegungsart: Inhaber, Stellvertreter, Nachfolger etc.</li>
          <li>Zahlreiche Informationen, Ist-Werte pro Stellenbelegung</li>
          <li>Eingebundene PC-Textverarbeitung</li>
        </ul>
      </div>
      <div className="post" id="ausbildung">
        <h3>Ausbildung</h3>
        Motivierte und gut ausgebildete Mitarbeitende tragen den Wandel in einem Unternehmen. Das Modul umfasst die Aus- und Fortbildungsplanung,
        Kursausschreibungen, die Kursverwaltung sowie deren Administration. Die Basis für die gesamte Verwaltung interner und externer Schulungen sind Personaldaten,
        Referenten und Kursangebote. Alle Auswertungen und die Korrespondenz werden automatisch erstellt. Pro Kurs sind Referenten, Kursräume, Infrastruktur,
        Hotelzimmer etc. zugeordnet. Folgende Funktionen stehen zur Verfügung:
                <br />
        <ul>
          <li>Verwaltung von Personen, Fähigkeiten/Profilen, Kursen, Kursserien, Referaten, Infrastruktur etc.</li>
          <li>Laufbahnplanung via Personenprofil</li>
          <li>Präsenzkontrolle, Kursbeurteilungen, Checklisten, Ausbildungsaktivitäten,</li>
          <li>Ausbildungs-, Kursprogramme: Beschriebe, Preisangaben</li>
          <li>Anmeldeverfahren inkl. Wartelisten, Vorreservierungen, Vorschläge usw.</li>
          <li>Reservationen: Räume, Kursräume, Hotels</li>
          <li>Fakturierung, Kostenkontrolle</li>
          <li>Kontrolle der Voraussetzungen sowie Auswertung von Ausbildungsvorschlägen</li>
          <li>Automatische Aktualisierung der Fähigkeiten im Personalstamm</li>
        </ul>
        <br />
        Das Modul bietet eine Reihe von Standardauswertungen. Ad hoc Auswertungen sind einfach und effizient realisierbar. Bsp. Einladungen, Bestätigungen,
        Teilnehmerlisten, Präsenzlisten. Kursangebote, Kursprogramme, Kurszertifikate, Soll/Ist-, Profilabgleiche, Kursvorschläge, Kursbewertungen, Referentenlisten,
        Checklisten für Infrastruktur, Reservationen für Räume, Hotelzimmer etc.
                <br />
        Eine Einbindung des Ausbildungsprozesses in ein HR-Portal bietet sich an.
            </div>
      <div className="post" id="revBudget">
        <h3>Salärrevision, Budgetierung</h3>
        Die Lohnbudgetierung bzw. -runde findet meist einmal pro Jahr statt und ist oft mit einer Organisationsänderung und/oder vorgängigen Qualifikationsrunde gekoppelt.
        Das Modul stützt diesen Prozess und erlaubt die Auswirkungen zu simulieren und die schliesslich verabschiedete Planvariante ins produktive HR-System zu übernehmen.
                <br /><br />
        Funktionsumfang, Verfahren:
                <br />
        <ul>
          <li>Vorbereitend Gruppen durch freie Selektion bilden: nach Vorgesetzten, Kostenstellen, Mitarbeiterkategorie, Geschlecht, Region etc.</li>
          <li>Der Produktivdatenbestand wird markiert und dynamisch mit temporären Einträgen und Tabellen ergänzt. Eventuelle Änderungen fliessen automatisch mit ein.</li>
          <li>Hinterlegung von kundenindividuellen Lohnberechnungsprogrammen pro Gruppe mittels Plugin-Technik: Lohnbänder, Sockel- oder Maximalbeträge, DA-Zuschläge etc.</li>
          <li>Im Top-down- oder Bottom-up-Verfahren über alle Hirarchiestufen hinweg solange simulieren, bis eine der theoretischen unbeschränkten Planvarianten passt.</li>
          <li>Eventuell neue Organisation über gewählte Budgetvariante legen. Budgetmässige Gegenüberstellung von Organisationsvarianten, wie alt, neu etc.</li>
          <li>Nach der Budgetverabschiedung die Revisionsdaten per Knopfdruck auf Stichtag aktualisiert übernehmen.</li>
        </ul>
        <br />
        Eine Einbindung des Revisionsprozesses in ein HR-Portal bietet sich an.
            </div>
      <div className="post" id="absenzen">
        <h3>Absenzen</h3>
        Die Absenzenverwaltung ist als Fehlzeit-Rapportierungsmodell konzipiert, die sinnvoll ist, wenn kein Bedarf für eine vollständige Zeit- bzw.
        Betriebsdatenerfassung besteht. Alle gewünschten Absenzarten können erfasst und verwaltet werden.
                <br /><br />
        Funktionsumfang:
                <br />
        <ul>
          <li>Lohnwirksame Parametrierung der Absenzarten</li>
          <li>Hinterlegung individueller Kalendermodelle</li>
          <li>Unterbruch erfasster Absenzen durch eine andere Absenz, z.B. Krankheit etc.</li>
          <li>Tages-, Halbtages- oder Stundenbasis</li>
          <li>Status der Absenzart, z.B. beantragt, bewilligt etc.</li>
          <li>Diverse Absenzkonti, z.B. Ferienübersicht, Krankheit, Weiterbildung etc.</li>
          <li>Einfach Auswertbarkeit durch Einbindung von Absenzarten in individuelle Konti Zusatzoptionen:</li>
        </ul>
        <br />
        Eine Einbindung des Absenzenprozesse in ein HR-Portal bietet sich an.
            </div>
      <div className="post">
        <h3>HR Reporting</h3>
        <h5>Wir machen aus Daten Informationen</h5>
        <p>
          Personalabteilungen sind Lieferanten für Auswertungen, die bei wichtigen Geschäftsentscheiden miteinbezogen werden. Die Qualität dieser Informationen
          prägt bleibend den Ruf einer gesamten Personalabteilung. Erschwerend kommt hinzu, dass komplexe, präzise Informationen innerhalb kurzer Frist vom HR
          bereitgestellt werden müssen.
                </p>
        <p>
          Dieser Informationsbedarf verlangt nach einem durchdachten Auswertungskonzept.
                    <br />
          Wir ermöglichen schnellen und einfachen Zugriff auf Daten und liefern somit Informationen. Wir realisieren für unsere Kunden effizientes,
          kundenspezifisches Reportingz.B. in den Bereichen Personalkennzahlen (Fluktuation, Lohnvergleiche, FTE und Headcounts,…), Organisation, Ausbildung und MbO.
                </p>
        <br />
        <LightBox type="images" images={hrReportingImages} thumbnailHeight={90} />
      </div>
      <div className="post">
        <h3>BI4HR - Business Intelligence for Human Resources Management</h3>
        <h5>Wir ertrinken in Informationen, aber wir hungern nach Wissen</h5>
        <p>
          Das Modul BI4HR für Xpert.HRM sorgt für ein einfaches, automatisiertes Reporting. Mit Business Intelligence for Human Resources (BI4HR) entnehmen
          Sie Ihren Personaldaten Informationen und gewinnen Sie durch systematisches Auswerten und Darstellen Erkenntnisse, die zielgerichtete operative und
          strategische Unternehmensentscheide ermöglichen.
                </p>
        <br />
        <LightBox type="images" images={bi4hrImages} thumbnailHeight={90} />
        <p>Weitere Infos finden Sie <a href="https://www.bi4hr.ch/">hier</a></p>
      </div>
      <div className="post">
        <h3>SpeedREP</h3>
        <h5>Schnelle Auswertungen</h5>
        <p>
          SpeedREP ist ein Tool um sehr schnell auf die Daten von Xpert.HRM zuzugreifen. Es läuft im Webbrowser und bietet die Möglichkeit User auf Reports zu
          berechtigen. Mit dem Tool wird auch eine Vielzahl von Auswertungen mitgeliefert, wie z.B:
                </p>
        <ul>
          <li>Liste aller Mitarbeiter</li>
          <li>Lohnarten pro Jahr und Monat</li>
          <li>Mitarbeiter ohne Funktion</li>
          <li>Eintrittsliste</li>
          <li>Austrittsliste</li>
          <li>Funktionenliste</li>
          <li>...</li>
        </ul>
        <p>Die Resultate werden direkt am Bildschirm angezeigt oder können jederzeit nach Excel exportiert werden.</p>
        <br />
        <LightBox type="images" images={speedRepImages} thumbnailHeight={90} />
      </div>
    </section>
  </Layout>
)

const overviewImages = [
  {
    index: 0,
    src: p1,
    title: 'Übersicht',
    description: ''
  },
  {
    index: 1,
    src: p2,
    title: 'Mitarbeiter',
    description: ''
  },
  {
    index: 2,
    src: p3,
    title: 'Organisation',
    description: ''
  },
  {
    index: 3,
    src: p4,
    title: 'Mitarbeiter Dokumente',
    description: ''
  },
  {
    index: 4,
    src: p5,
    title: 'Mitarbeiterskills',
    description: ''
  }
];

const p1Images = [
  {
    index: 0,
    src: p6,
    title: 'Lohninput',
    description: ''
  },
  {
    index: 1,
    src: p7,
    title: 'Abrechnung',
    description: ''
  },
  {
    index: 2,
    src: p8,
    title: 'Kinder',
    description: ''
  },
  {
    index: 3,
    src: p9,
    title: 'Lohnlauf',
    description: ''
  }
];

const hrReportingImages = [
  {
    index: 0,
    src: p10,
    title: 'Fehlzeiten pro OE',
    description: ''
  },
  {
    index: 1,
    src: p11,
    title: 'Budget',
    description: ''
  },
  {
    index: 2,
    src: p12,
    title: 'Nationalität - Teilzeit',
    description: ''
  },
  {
    index: 3,
    src: p13,
    title: 'Dienstalter',
    description: ''
  }
];

const bi4hrImages = [
  {
    index: 0,
    src: p14,
    title: 'HR Cockpit',
    description: ''
  },
  {
    index: 1,
    src: p15,
    title: 'FTE pro OE',
    description: ''
  },
  {
    index: 2,
    src: p16,
    title: 'Fluktuation pro OE',
    description: ''
  },
  {
    index: 3,
    src: p17,
    title: 'Lohnänderungen',
    description: ''
  },
  {
    index: 4,
    src: p18,
    title: 'Saläranalyse pro Funktion',
    description: ''
  },
  {
    index: 5,
    src: p19,
    title: 'Jahreslohn pro Funktion',
    description: ''
  }
];

const speedRepImages = [
  {
    index: 0,
    src: p20,
    title: 'Main',
    description: ''
  },
  {
    index: 1,
    src: p21,
    title: 'Auswertung',
    description: ''
  },
  {
    index: 2,
    src: p22,
    title: 'Excel',
    description: ''
  }
];

export default xpert
